import React from 'react';
import './OurLocations.css'; // Import custom CSS

const OurLocations = () => {
  return (
    <div
      style={{
        backgroundImage: `url('/assets/our-location-bg.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor:"#9D332C"
      }}
      className='py-10 our-locations-container'>
      <h1 className='our-locations-heading'>Our Locations</h1>
      <p className='relative z-10 our-locations-subheading'>Property Availability</p>
      {/* <img className='our-locations-map' src='' alt='Our Location Map' /> */}
      <img className='map-image' src='/assets/our-location-map.png' alt='Our Location Map' />
    </div>
  );
};

export default OurLocations;