
import React, { useState, useEffect } from "react";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import "./Companies.css";
import Partners from "./design-enhancements/Partners/Partners";

// Define your translations here for all languages
const texts = {
  en: {
    heading: "Companies that choose",
    subheading: "Zacatecas",
  },
  es: {
    heading: "CONOZCA A NUESTROS INVERSORES",
    subheading: "Zacatecas",
  },
  de: {
    heading: "TREFFEN SIE UNSERE INVESTOREN",
    subheading: "Zacatecas",
  },
  ja: {
    heading: "私たちの投資家に会いましょう",
    subheading: "サカテカス",
  },
  zh: {
    heading: "见见我们的投资者",
    subheading: "萨卡特卡斯",
  },
  ko: {
    heading: "투자자들을 만나보세요",
    subheading: "자카테카스",
  },
  fr: {
    heading: "RENCONTREZ NOS INVESTISSEURS",
    subheading: "Zacatecas",
  },
};



export default function Companies() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [lang, setlang] = useState(Cookies.get("language") || "en");

  // Handle mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update language from cookies
  useEffect(() => {
    const interval = setInterval(() => {
      const savedLanguage = Cookies.get("language");
      if (savedLanguage) {
        setlang(savedLanguage);
      }

    }, 1); // Check every 1 second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);



  const { heading, subheading, } = texts[lang];





  return (
    <div
      className="strategic-border"
      style={{
        backgroundColor: "#F1F1F1",
        width: "100%",
        display: "flex",
        padding: "50px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        fontWeight: "bold",
        borderTop: "5px solid #efbcbc",
        border: "5px solid #efbcbc",
      }}
    >
      <div className="col-lg-10 col-md-12 d-flex flex-column align-items-center justify-content-center">
        <div className="mt-3">
          <h1
            style={{
              color: "white",
              fontWeight: "bold",
            }}
            className="text-center text-black">{heading}</h1>
          <h1 className="text-center"
            style={{

              fontWeight: "bold",
            }}
          >{subheading}</h1>
          <div className="Company-underline"></div>
        </div>

        <Partners />

      </div>
    </div>
  );
}
